import React, { useState , useEffect } from 'react'
import Popup from '../components/Popup';
import SidebarPopup from '../components/SidebarPopup'
import { Link } from 'react-router-dom'
import axios from "axios";
import { BASE_URL } from '../config';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from '../components/Footer';
import parse from 'html-react-parser';
toast.configure();



function WhyUseSealBid() {

	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [title, setTitle] = useState('');
	const [content, setContent] = useState('');

	const toggleSidebarPopup = () => {
	  setIsSidebarOpen(!isSidebarOpen);
	}
	const getCMSdata= ()=>{
		
		axios.get(`${BASE_URL}/admin/cmsList/whysealbid`)
		.then((res) => {
			console.log("res",res.data)
			if(res.data.success){
				// setEmail(res.data.userInfo.email);
				// toast.success(res.data.message)
				setTitle(res.data.data.title)
				setContent(res.data.data.content)
			}else{
				toast.error(res.data.message)
				console.log(res.data)
			}
			
		}).catch((error) => {
			toast.error(error)
			console.log(error)
		});
	
	
}


useEffect( ()=>{
	getCMSdata();
  },[])
  return (
	<>
	 
	 <div className="maincontent_wrap">

  <div className="maincontent_details fullcontent_view">
	<div className="home_content_block">
		<div className="maincont_top">
			<div className="mob_logo">
				<a href="#">
				<img src="../assets/images/logo.png" alt=""/>
				</a>
			</div>
			<div className="setup_info">
				
				<a onClick={toggleSidebarPopup}><img src="../assets/images/setup.svg" alt=""/></a>

			</div>
		</div>
	
		<div className="inner_cont_wrap">
			<div className="heading_info">
				<h2>{title}</h2>
			</div>
			{parse(content)}
			{/* <div className="inner_cont_block">
				<div className="inner_cont_des">
					<p>Let’s say you are working with several other colleagues to get a quotation for a specific product or service.</p>
					<div className="add_img">
						<img src="../assets/images/addimage.png" alt=""/>
					</div>
					<p>At least one of them has prior links to a supplier.</p>
					<p>You want to include that supplier as part of your consideration but do not want any unwanted interference or under-table deals between your colleagues and suppliers</p>
					<p>You also don’t want your other colleagues to leak information about other offers received by the group to any supplier, unfairly biasing against others. This is where seal-bid will be very useful to you.</p>
					
				</div>

					

			
			</div> */}

                   { isSidebarOpen && <SidebarPopup
                                    content={<>
								    
								

									   <div className="dropdown_info">
										<ul>
									   <li><Link to="/WhyUseSealBid"><img src="../assets/images/why_seal_bid.svg" alt=""/>why seal bid?</Link></li>
									   {/* <li><img src="../assets/images/usertype.svg" alt=""/>User Type</li> */}
									   <li><Link to="/HowSealBidWorks"><img src="../assets/images/howitwork.svg" alt=""/>How it works?</Link></li>
									   <li><Link to="/Contact"><img src="../assets/images/contactus_icon.svg" alt=""/>Contact us</Link></li>
									   </ul>

									   </div>
   
                                     </>}
                               handleClose={toggleSidebarPopup}
                               />}	
		
			
		</div>
	</div>
</div>
</div>

   <Footer/>
	
	</>
  )
}

export default WhyUseSealBid