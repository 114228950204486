import React, { useState,useEffect }  from 'react'
import SidebarPopup from '../components/SidebarPopup'
import { useHistory, NavLink, Link,useLocation , useNavigate , useParams} from "react-router-dom";
import axios from "axios";
import { BASE_URL } from '../config';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from '../components/Footer';

function Contact() {


    const navigate = useNavigate();
	
    const [category, setCategory] = useState([])
	const [selectedOption, setSelectedOption] = useState();
    // const [username, setName] = useState('')
    const [email, setEmail] = useState('')
	const[message,setMessage]=useState('')


    // contactus data insert API  call
    const insertData= async(e)=>{
        e.preventDefault();
        
        let contactusData = {
		
			"category":selectedOption,
            // "name":username,
            "email":email,
			"message":message
		};

        console.log("data",contactusData) 

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if(selectedOption === undefined){
            toast.error("Please select category")
        }
        // else 
        // if (username === '') {
		// 	toast.error("Please fill up your name")
		// }

        else if (email === '') {
			toast.error("Please fill up your emailid")
		}
		else if(!email.match(validRegex)){
			toast.error("Please fill up with valid emailid")

		}
        
        else if (message === '') {
			toast.error("Please fill up message")
		}


        else {
			axios.post(`${BASE_URL}/user/contact-us`, contactusData)
            .then((res) => {
				if(res.data.success){
				
					toast.success(res.data.message)
                    window.location.reload();
                	console.log(res.data)
				}else{
					toast.error(res.data.message)
                	console.log(res.data)
				}
				
            }).catch((error) => {
				toast.error(error)
                console.log(error)
            });

		}

        // window.location.reload();
      
    }



    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

	const toggleSidebarPopup = () => {
	  setIsSidebarOpen(!isSidebarOpen);
	}




    // get all category details
	function getcategory(){
		axios.get(`${BASE_URL}/user/get-category`)
		   .then((res) => {
			   if(res.data.success){
				  
				   setCategory(res.data.data);
				   console.log("All category details",res.data.data)
			   }else{
				  
				   console.log(res.data)
			   }
			   
		   }).catch((error) => {
			   toast.error(error)
			   console.log(error)
		   });

	}

    useEffect( ()=>{
        getcategory();
    
      },[])



  return (
	<>
       <div class="maincontent_wrap">

<div className="maincontent_details fullcontent_view">
    <div className="home_content_block">
        <div className="maincont_top">
            <div className="mob_logo">
                <a href="#">
                <img src="../assets/images/logo.png" alt=""/>
                </a>
            </div>
            <div className="setup_info">
      
                <a onClick={toggleSidebarPopup}  ><img src="../assets/images/setup.svg" alt=""/></a>

            </div>
        </div>
    
        <div className="inner_cont_wrap">
            <div className="heading_info">
                <h2>Contact seal-bid.com</h2>
            </div>
            <div className="contactinfo">
                <div className="contactinfo_add">
                    <img src="../assets/images/conticon.png" alt=""/>
                </div>
                <div className="contactinfo_text">
                    <div className="from_content">

                        <div className="inp_row">
                            <div className="inp_txt_view">
                                
                                <select className="form-control form-inp2" name="category" value={selectedOption}
                                 onChange={(event) => setSelectedOption(event.target.value)}>
                                <option disabled selected value>Choose your Category</option>
								       {category.map((categoryd) => {
                                        return <option key={categoryd._id} value={categoryd._id}>{categoryd.name}</option>
                                          })}   
                                </select>
                            </div>
            
                            
                            <div className="error_info"></div>
                        </div>

                        {/* <div className="inp_row">
                        <div className="inp_txt_view">
                                <div className="inp_icon">
                                    <img src="../assets/images/name.jpg" alt=""/>
                                </div>
                                <input type="text" name="name" onChange={e => setName(e.target.value)} value={username} className="form-control" placeholder="Enter Your Name..."/>
                            </div>
                            <div className="error_info"></div>
                            
                        </div>  */}

                        <div className="inp_row">
                        
                            <div className="inp_txt_view">
                                <div className="inp_icon">
                                    <img src="../assets/images/email.png" alt=""/>
                                </div>
                                <input type="text" name="email" onChange={e => setEmail(e.target.value)} value={email} className="form-control" placeholder="Enter Your Email Address..."/>
                            </div>
                            <div className="error_info"></div>
                        </div>
                        <div className="inp_row">
                            <div className="inp_txt_view">
                            <textarea className="form-control" name="message" onChange={e => setMessage(e.target.value)} value={message}  placeholder="Type Your Message Here..."></textarea>
                            </div>
                            <div className="error_info"></div>
                        </div>

                        <div className="def_btn_opt">
                            <button type="submit" className="defbtn" onClick={insertData}>send</button>	



                            {isSidebarOpen && <SidebarPopup
                                    content={<>
								    
								

									   <div className="dropdown_info">
										<ul>
									   <li><Link to="/WhyUseSealBid"><img src="../assets/images/why_seal_bid.svg" alt=""/>why seal bid?</Link></li>
									   {/* <li><img src="../assets/images/usertype.svg" alt=""/>User Type</li> */}
									   <li><Link to="/HowSealBidWorks"><img src="../assets/images/howitwork.svg" alt=""/>How it works?</Link></li>
									   <li><Link to="/Contact"><img src="../assets/images/contactus_icon.svg" alt=""/>Contact us</Link></li>
									   </ul>

									   </div>
   
                                     </>}
                               handleClose={toggleSidebarPopup}
                               />}											
                            </div>
                            <div className="error_info"></div>
                        </div>
                    </div>
                </div>
            

            
            </div>
            
        </div>
    </div>
</div>
	
	</>
  )
}

export default Contact