import React from "react";
import { HashRouter, Routes, Route } from 'react-router-dom';
//import Demo from "./pages/Demo";

import Home from './pages/Home'
import UploadTender from "./pages/UploadTender";
import WantToTender from "./pages/WantToTender";
import Signup from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import GetNotified from './pages/GetNotified';
import MyAccount from "./pages/MyAccount";
import ActiveTenderBox from "./pages/ActiveTenderBox";
import UploadTender1 from "./pages/UploadTender1";
import WhyUseSealBid from "./pages/WhyUseSealBid";
import BuyersAndSuppliers from "./pages/BuyersAndSuppliers";
import Contact from "./pages/Contact";
import SealBidDashboard from "./pages/SealBidDashboard";
import Tender_Name from "./pages/Tender_Name";
import TermsOfUse from "./pages/TermsOfUse";
import EditPrivileges from "./pages/EditPrivileges";
import HowSealBidWorks from "./pages/HowSealBidWorks";
import CreatePassword from "./pages/CreatePassword";
import ResetPassword from './pages/ResetPassword';
import EditPrivilegesConfirmation from "./pages/EditPrivilegesConfirmation";
import UploadTenderlogin from "./pages/UploadTenderlogin";
import WantToTenderlogin from "./pages/WantToTenderlogin";
import NewTender from "./pages/NewTender";
import EditTenderBox from "./pages/EditTenderBox";


//import { useState } from "react";


function App(){


 
return(
    <>
   
     < HashRouter>
        <Routes>
        <Route path="/" element={<Home />} /> 
        <Route path = "/UploadTender" element={<UploadTender />}/>
        <Route path = "/UploadTenderlogin" element={<UploadTenderlogin />}/>
        <Route path = "/WantToTender" element={<WantToTender />}/>
        <Route path = "/WantToTenderlogin" element={<WantToTenderlogin />}/>
        <Route path = "/SignUp" element={<Signup />}/>
        <Route path = "/SignIn" element={<SignIn />}/>
        <Route path = "/ForgotPassword" element={<ForgotPassword />}/>
        <Route path = "/ChangePassword" element={<ChangePassword />}/>
        <Route path = "/GetNotified/:id/:email" element={<GetNotified />}/>
        <Route path = "/MyAccount" element={<MyAccount />}/>
        <Route path = "/ActiveTenderBox" element={<ActiveTenderBox />}/>
        <Route path = "/UploadTender1/:id/:email" element={<UploadTender1 />}/>
        <Route path = "/WhyUseSealBid" element={<WhyUseSealBid />}/>
        <Route path = "/BuyersAndSuppliers" element={<BuyersAndSuppliers />}/>
        <Route path = "/Contact" element={<Contact />}/>
        <Route path = "/SealBidDashboard" element={<SealBidDashboard />}/>
        <Route path = "/TenderName" element={<Tender_Name />}/>
        <Route path = "/NewTender" element={<NewTender/>}/>
        <Route path = "/TermsOfUse" element={<TermsOfUse />}/>
        <Route path = "/EditPrivileges" element={<EditPrivileges />}/>
        <Route path = "/HowSealBidWorks" element={<HowSealBidWorks />}/>
        <Route path = "/privilegesconfirmation" element={<EditPrivilegesConfirmation />}/>
        <Route path = "/CreatePassword/:id" element={<CreatePassword />}/>
        <Route path = "/ResetPassword/:id" element={<ResetPassword />}/>
        <Route path="/EditTenderBox" element={<EditTenderBox/>}/>
        </Routes>
      </ HashRouter>
     {/* <UploadTender/> */}
     {/* <Home/> */}
           
   </>
  )
}

export default App;







