import React, { useState, useEffect }  from 'react'
import SidebarPopup from '../components/SidebarPopup'

import { Link } from 'react-router-dom'
import Footer from '../components/Footer';
import parse from 'html-react-parser';
import axios from "axios";
import { BASE_URL } from '../config';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

function HowSealBidWorks() {

	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [title, setTitle] = useState('');
	const [content, setContent] = useState('');

	const toggleSidebarPopup = () => {
	  setIsSidebarOpen(!isSidebarOpen);
	}

	const getCMSdata= ()=>{
		
		axios.get(`${BASE_URL}/admin/cmsList/how-it-works`)
		.then((res) => {
			console.log("res",res.data)
			if(res.data.success){
				// setEmail(res.data.userInfo.email);
				// toast.success(res.data.message)
				setTitle(res.data.data.title)
				setContent(res.data.data.content)
			}else{
				toast.error(res.data.message)
				console.log(res.data)
			}
			
		}).catch((error) => {
			toast.error(error)
			console.log(error)
		});
	
	
}

useEffect( ()=>{
	getCMSdata();
  },[])


  return (
	<>
	 
	 <div className="maincontent_wrap">

<div className="maincontent_details fullcontent_view">
	<div className="home_content_block">
		<div className="maincont_top">
			<div className="mob_logo">
				<a href="#">
				<img src="../assets/images/logo.png" alt=""/>
				</a>
			</div>
			<div className="setup_info">
				
				<a onClick={toggleSidebarPopup}><img src="../assets/images/setup.svg" alt=""/></a>

			</div>
		</div>
	
		<div className="inner_cont_wrap">
			<div className="heading_info">
				{/* <h2>How seal-bid works</h2> */}
				<h2>{title}</h2>
			</div>
			<div className="inner_cont_block">
				<div className="inner_cont_des">
					{/* <p>seal-bid.com offers a secure virtual tender box to collect and keep commercially sensitive information away from prying eyes. If you work with a coalition of business partners who want to attractive competitive bids, sign up for a seal-bid account to create your virtual tender box free of charge. If you are a supplier and wants your offer to be evaluated based on its unique merits, ask your buyer to create a virtual tender box for you to upload your offers (“tenders”) – then you can request to be notified for other buyers’ tender requests.
					</p> */}
					{parse(content)}
					<div className="howsealdid_info">
						<div className="howsealdid_info_top">
							<div className="howsealdid_top_left">
								<div className="howsealdid_cont">
									Registered buyer sets up a tender box
								</div>
								<div className="howsealdid_cont">
									Seal-bid helps registered buyer inform prospective suppliers of tender box & tender details
								</div>
							</div>
							<div className="howsealdid_top_right">
								<div className="howsealdid_cont">
									Tender box closes; seal-bid lets buyer access uploaded offers ("tenders")
								</div>
								<div className="howsealdid_cont">
									Buyer can choose to keep the details of the incoming tenders for a long time, for record-keeping.

								</div>
							</div>


						</div>
						<div id="container">
                             <div class="arrow-right"></div>
                        </div>
						<div className="howsealdid_info_bottom">
							<div className="howsealdid_bottom_cont">
								<div className="howsealdid_cont">
									Tender box opens; Suppliers upload their offers ("tenders") on seal bid
								</div>
								<div className="howsealdid_cont">
									Uploaded offers ("tenders") cannot be accessed by anyone until the designated time, so no sensitive info is leaked.
								</div>
							</div>
						</div>

						{isSidebarOpen && <SidebarPopup
                                    content={<>
								    
								

									   <div className="dropdown_info">
										<ul>
									   <li><Link to="/WhyUseSealBid"><img src="../assets/images/why_seal_bid.svg" alt=""/>why seal bid?</Link></li>
									   {/* <li><img src="../assets/images/usertype.svg" alt=""/>User Type</li> */}
									   <li><Link to="/HowSealBidWorks"><img src="../assets/images/howitwork.svg" alt=""/>How it works?</Link></li>
									   <li><Link to="/Contact"><img src="../assets/images/contactus_icon.svg" alt=""/>Contact us</Link></li>
									   </ul>

									   </div>
   
                                     </>}
                               handleClose={toggleSidebarPopup}
                               />}						
					</div>
					
				</div>

			
			</div>
			
		</div>
	</div>
</div>
</div>

   <Footer/>
	
	</>
  )
}

export default HowSealBidWorks