import React from "react";





const SidebarPopup = props => {
  return (
    <div style={{
      position: "fixed",
      background: "#00000050",
      width: "100%",
      height: "100vh",
      top: "0",
      left: "0"}}>
      <div className="popup_cont_info">
        <span className="popupclose"
        
        onClick={props.handleClose}>x</span>
        {props.content}
      </div>
    </div>
  );
};

export default SidebarPopup;
