export const timezone=[
   
    {
        "id": "1",
        "name": "UTC –Coordinated Universal Time",
        "value": "UTC –Coordinated Universal Time"

    },
    {
        "id": "2",
        "name": "British Summer Time UTC+1",
        "value": "British Summer Time UTC+1"

    },

    {
        "id": "3",
        "name": "China/Singapore UTC+8",
        "value": "China/Singapore UTC+8"

    },
    {
        "id": "4",
        "name": "Australian Eastern Standard UTC+10",
        "value": "Australian Eastern Standard UTC+10"

    },
    {
        "id": "5",
        "name": "Australian Eastern Daylight Savings UTC+11",
        "value": "Australian Eastern Daylight Savings UTC+11"

    },
    {
        "id": "6",
        "name": "US Pacific Daylight Saving UTC -7",
        "value": "US Pacific Daylight Saving UTC -7"

    },
    {
        "id": "7",
        "name": "US Eastern Standard UTC -5",
        "value": "US Eastern Standard UTC -5"

    },
    {
        "id": "8",
        "name": "US Eastern Daylight Savings -4",
        "value": "US Eastern Daylight Savings -4"

    },
    {
        "id": "9",
        "name": "Pacific Standard Time (UTC -8)",
        "value": " Pacific Standard Time (UTC -8)"

    }


]