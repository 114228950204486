import React from "react";





const DelTenderPopup = props => {
  return (
    <div style={{
      position: "fixed",
      background: "#00000050",
      width: "100%",
      height: "100vh",
      top: "0",
      left: "0"}}>
      <div style={{
         position: "relative",
         width: "50%",
         margin: "0 auto",
         height: "200px",
         maxHeight:"170vh",
         marginTop: "calc(100vh - 85vh - 20px)",
         background: "#fff",
         borderRadius: "5px",
         padding: "10px",
         paddingTop : "40px",
         border: "1px solid #999",
         overflow: "auto"

      }}>
        <span className="deletepopupclose"
        
        onClick={props.handleClose}>x</span>
        {props.content}
      </div>
    </div>
  );
};

export default DelTenderPopup;
