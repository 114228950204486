import React, { useState, useEffect }  from 'react'
import SidebarPopup from '../components/SidebarPopup'

import { Link } from 'react-router-dom'
import Footer from '../components/Footer';
import axios from "axios";
import { BASE_URL } from '../config';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import parse from 'html-react-parser';
toast.configure();

function BuyersAndSuppliers() {
   
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [title, setTitle] = useState('');
	const [content, setContent] = useState('');


	const toggleSidebarPopup = () => {
	  setIsSidebarOpen(!isSidebarOpen);
	}


	const getCMSdata= ()=>{
		
		axios.get(`${BASE_URL}/admin/cmsList/buyer-supplier`)
		.then((res) => {
			console.log("res",res.data)
			if(res.data.success){
				// setEmail(res.data.userInfo.email);
				// toast.success(res.data.message)
				setTitle(res.data.data.title)
				setContent(res.data.data.content)
			}else{
				toast.error(res.data.message)
				console.log(res.data)
			}
			
		}).catch((error) => {
			toast.error(error)
			console.log(error)
		});
	
	
}

useEffect( ()=>{
	getCMSdata();
  },[])

  return (
	<>
	 
 <div className="maincontent_wrap">

<div className="maincontent_details fullcontent_view">
	<div className="home_content_block">
		<div className="maincont_top">
			<div className="mob_logo">
				<a href="#">
				<img src="../assets/images/logo.png" alt=""/>
				</a>
			</div>
			<div className="setup_info">
				
				<a onClick={toggleSidebarPopup} ><img src="../assets/images/setup.svg" alt=""/></a>

			</div>
		</div>
	
		<div className="inner_cont_wrap">
			<div className="heading_info">
				{/* <h2>Buyers and Suppliers?</h2> */}
				<h2>{title}</h2>
			</div>

			{parse(content)}
			{/* <div className="inner_cont_block">
				<div className="inner_cont_des">
                    


					<p><strong>You don’t need to pay anything to enjoy what seal-bid offers.</strong></p>
					<p>Buyers of products or services sign up at seal-bid to invite offers, or “tenders”, from prospective Suppliers. These “tenders” will be stored at seal-bid and not be accessible until a pre-determined time, reducing the possibility of insiders leaking competitively sensitive info to companies they befriend.</p>
					<div className="add_block_info">
						<div className="add_img">
							<img src="../assets/images/add1.png" alt=""/>
							<p><strong>The Buyer Sends a Request for Render(RfT)</strong></p>
						</div>
						<div className="add_img">
							<img src="../assets/images/add2.png" alt=""/>
							<p><strong>The Buyer Sends a Request for Render(RfT)</strong></p>
						</div>
					</div>
					
					<p>Suppliers upload their offers (“tenders”) on seal-bid know that their offers will be read at the same time as other offers.
						Suppliers are not required to keep an account at seal-bid. They can ask to be notified about future invitations for tenders by pressing the ‘Want to tender’ button on the left.</p>
						
					<p>Remember to use secure data transmission to further reduce chances of data leaks
						</p>


									
					
				</div>

			
			</div> */}

			{isSidebarOpen && <SidebarPopup
                                    content={<>
								    
								

									   <div className="dropdown_info">
										<ul>
									   <li><Link to="/WhyUseSealBid"><img src="../assets/images/why_seal_bid.svg" alt=""/>why seal bid?</Link></li>
									   <li><img src="../assets/images/usertype.svg" alt=""/>User Type</li>
									   <li><Link to="/HowSealBidWorks"><img src="../assets/images/howitwork.svg" alt=""/>How it works?</Link></li>
									   <li><Link to="/Contact"><img src="../assets/images/contactus_icon.svg" alt=""/>Contact us</Link></li>
									   </ul>

									   </div>
   
                                     </>}
                               handleClose={toggleSidebarPopup}
                               />}			
			
		</div>
	</div>
</div>
</div>

   <Footer/>
	
	</>
  )
}

export default BuyersAndSuppliers